<template>
    <div>
        <HeaderNavigation :info="$store.state.infos" :checkValue="3" />
        <div class="top_pic"><img :src="banner" alt=""></div>
        <div>
            <div class="two_box">
                <div class="title_txt">{{us.title1}}</div>
                <div class="left_right_box">
                    <div class="right_box">
                        <div class="content_txt" v-html="us.introduction"></div>
                        <!-- <div class="content_txt" v-html="us.system"></div> -->
                    </div>
                    <div class="left_box">
                        <img :src="us.company_image" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="support_box">
            <div class="title_txt">{{us.title2}}</div>
            <div class="support_txt" v-html="us.system"></div>
        </div>
        <BottomNavigation :info="$store.state.infos" />
        <RightMenu :info="$store.state.infos" @openpop="openpop" />
        <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
    <Tips></Tips>
    </div>
</template>

<script>
    import {indexData,cateBanner} from '@/api/index.js'
    import HeaderNavigation from "@/components/header_navigation.vue";
    import BottomNavigation from "@/components/bottom_navigation.vue";
    import RightMenu from "@/components/right-menu.vue";
    import JoinBulletFrame from "@/components/joinBulletFrame.vue";
import Tips from "@/components/tips.vue";
    export default {
        components: {
            HeaderNavigation,
            BottomNavigation,
            RightMenu,
            JoinBulletFrame,
    Tips
        },
        data() {
            return {
                JoinModal:false,
                banner:'',
                us:{}
            }
        },
        mounted() {
            this.cateBanner()
            this.indexData()
        },
        methods:{
            cateBanner(){
                cateBanner({id:3}).then(res=>{
                    this.banner = res.data
                })
            },
            indexData(){
                indexData().then(res=>{
                    this.us = res.data.us
                })
            },
            openpop(e){
                this.JoinModal = e
            },
            closepop(e){
                this.JoinModal = e
            },
            handler(){
                
            }
        }
    }
</script>

<style scoped>
.top_pic{
    width: 100%;
    height: 600px;
}
.top_pic img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.two_box {
  width: 100%;
  padding: 50px 0;
  /* background: #f3f3f3; */
}
.left_right_box {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.right_box {
  padding-right: 40px;
  width: 40%;
}
.left_box {
  width: 60%;
}
.left_box img {
  width: 100%;
  /* width: 570px; */
  /* height: 344px; */
}
.content_txt {
  font-size: 14px;
  margin-top: 20px;
}
.certificate{
  width: 90%;
  display: flex;
  background: #F9F9F9;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
}
.certificate img{
  width: 200px;
  height: 160px;
}
.certificate_text{
  text-align: center;
  padding: 10px 0;
}
.details_btn {
  background: #be3721;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 50px;
  padding: 10px 0;
}
.title_txt{
    font-size: 28px;
    color: #000;
    text-align: center;
}
.support_box{
    width: 80%;
    margin: 0 auto 50px;
}
.support_txt{
    padding: 20px 0;
}
</style>